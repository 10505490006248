import React from "react"
import { graphql } from "gatsby"

import Seo from "../components/seo"
import Layout from "../components/layout"

import WaveHeader from "../components/waveHeader"
import Pagination from "../components/pagination"
import PostsGrid from "../components/postsGrid"


const HubTemplate = ({ data, pageContext }) => {

  const { tagName } = pageContext
  const posts = data.allWpPost.nodes

  return (
    <Layout>
      <Seo title={`Tag: ${tagName}`} />

      <WaveHeader
        title={`Posts tagged: ${tagName}`}
        pageOrPost='PAGE'
      />


      <div className='main-container'>

        <section>
          <PostsGrid
            posts={posts}
          />
        </section>

        <Pagination pageContext={pageContext} />

      </div>

    </Layout>
  )
}

export const pageQuery = graphql`
query($tagId: Int!, $skip: Int!, $limit: Int!) {
  allWpPost(
    sort: { fields: date, order: DESC }
    skip: $skip
    limit: $limit
    filter: { tags: { nodes: { elemMatch: { databaseId: { eq: $tagId } } } } }
  ) {
      nodes {
        date
        databaseId
        excerpt
        uri
        slug
        title
        author {
          node {
            name
          }
        }
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                fixed(height: 300) {
                  ...GatsbyImageSharpFixed
                }
                fluid(maxWidth: 500) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
  `

export default HubTemplate

